body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

div {
    -webkit-tap-highlight-color: transparent;
}

img {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

input[type="number"] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

iframe[src*="googlesyndication"] {
    display: none !important;
}

:root {
    --main-shadow: 0 3px 20px 2px rgba(0, 0, 0, 0.3);
    --text: #CBDED6;
    --background: #001F13;
}

.form-global-style {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 500px;
    align-self: center;
}

.form-global-style-solid {
    background: linear-gradient(0deg, #002919, #001F13);
    border-radius: 10px;
    padding: 10px;
    margin: 20px 0;
}

@media only screen and (orientation: landscape) and (max-width: 900px) and (hover: none) and (pointer: coarse) {
    html {
        transform: rotate(-90deg);
        transform-origin: left top;
        width: 100vh;
        height: 100vw;
        overflow-x: hidden;
        position: absolute;
        top: 100%;
        left: 0;
    }
}