.container-box {
    background: linear-gradient(0deg, #001F13, #B3E0CB);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-height: 100vh;
    user-select: none;
    align-items: center;
}

.container-box p {
    color: var(--text)
}

.container-box h1 {
    opacity: 0.4;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 45px;
}

.login-form-container {
    background: #001F13;
    opacity: 0.7;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 350px;
    box-shadow: var(--main-shadow);
    padding: 20px 40px;
    box-sizing: border-box;
}

.container-sesion h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: lighter;
}


.container-sesion button {
    justify-content: center;
    align-items: center;
}