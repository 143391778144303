.password-reset-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    user-select: none;
    text-align: center;
}

.tittle-box h1 {
    color: #00DF8A;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 45px;
    opacity: 0.8;
}

.tittle-box h3 {
    opacity: 0.7;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: lighter;
}

.correo-box {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 130px;
    border-radius: 5px;
    border-style: solid;
    margin: 20px;
}

.codigo-box {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 20px;
    border-radius: 5px;
    border-style: solid;
}

.new-password-box {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 20px;
    border-radius: 5px;
    border-style: solid;
}

.password-reset-box h5 {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: lighter;
    opacity: 0.8;
}